import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField, Tooltip } from '@mui/material';
import { navigate } from 'gatsby-link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import * as addressSearchApi from '../../api/addressSearchApi';
import * as dataApi from '../../api/dataApi';
import './index.css';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import GeneralPopup from '../GeneralPopup';

function FormattedAddress({ address, secondary }) {
  return (
    <span>
      {address.unit && address.street_line.indexOf(address.unit) !== -1 ? (
        <span
          dangerouslySetInnerHTML={{
            __html: address.street_line.replace(
              address.unit,
              '<strong>' + address.unit + '</strong>'
            )
          }}></span>
      ) : (
        address.street_line
      )}
      {secondary ? <strong> {address.secondary}</strong> : null}
      {address.street_line ? <br /> : null}
      {address.city}, {address.state} {address.zipcode}
      {address.uniqueAddressId ? (
        <>
          <br />
          <strong>ESI-ID {address.uniqueAddressId}</strong>
          {address.isBusiness ? (
            <span
              className="badge"
              style={{
                marginLeft: '10px',
                background: '#ff3366',
                color: '#ffffff'
              }}>
              Business Address
            </span>
          ) : null}
        </>
      ) : null}
    </span>
  );
}

function AddressSearch({
  actions,
  modal,
  onSelected,
  onAddressSearched,
  webSocket,
  serviceAddress,
  addressLabel,
  searchButtonLabel,
  mode,
  hideSearchBy,
  siteConfig,
  hideHistory,
  isPreviousAddress,
  autoFocus,
  handleAddressSelection,
  afterAddressSelected,
  suppressSecondarySelect,
  placeholderOnly,
  authUser,
  hideAddressSearchButton
}) {
  const [addressSearchString, setAddressSearchString] = useState('');
  const [secondarySearchString, setSecondarySearchString] = useState('');
  const addressSearchTimeout = useRef();
  const secondarySearchTimeout = useRef();
  const ref = useRef();
  const [addressList, setAddressList] = useState();
  const [secondaryEntryDictionary, setSecondaryEntryDictionary] = useState({});
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 668;
  const [addressMode, setAddressMode] = useState(mode ? mode : 'address');
  const [zipCode, setZipCode] = useState('');
  const [addressSearchShowZip, setAddressSearchShowZip] = useState(
    !dataApi.getCookie('sc-zip-code')
  );
  const [inFocus, setInFocus] = useState(null);
  const [showServiceProfileList, setShowServiceProfileList] = useState(null);

  useEffect(() => {
    const zipFromCookie = dataApi.getCookie('sc-zip-code');
    if (zipFromCookie) {
      setZipCode(zipFromCookie);
    }

    if (!isMobile) {
      if (document.getElementById('address_search_input')) {
        document.getElementById('address_search_input').focus();
      }
    }

    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (addressSearchTimeout.current) {
        clearTimeout(addressSearchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Address &&
      !addressSearchString
    ) {
      setAddressSearchString(serviceAddress.Address.Address);
    }
  }, [serviceAddress]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (secondarySearchTimeout.current) {
        clearTimeout(secondarySearchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isFocused && isMobile) {
      setTimeout(() => {
        var userAgent = window.navigator.userAgent;

        const yOffset = -70;
        if (ref.current) {
          const element =
            ref.current.getElementsByClassName('address-search')[0];
          if (element) {
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        }
      }, 200);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!mode) {
      if (serviceAddress) {
        if (serviceAddress.Address) {
          setAddressMode('address');
        } else {
          setAddressMode('address');
        }

        if (
          serviceAddress.Address &&
          serviceAddress.Address.Zip &&
          serviceAddress.Address.Zip !== zipCode
        ) {
          setZipCode(serviceAddress.Address.Zip);
        }
      }
    }
  }, [serviceAddress]);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (secondarySearchTimeout.current) {
        clearTimeout(secondarySearchTimeout.current);
      }
    };
  }, []);

  function searchAddress(searchString) {
    if (searchString && searchString.length > 2) {
      if (siteConfig.id === 'mi') {
        addressSearchApi.searchAddress(searchString, '').then((response) => {
          if (response.suggestions) {
            setAddressResults(response.suggestions);

            if (onAddressSearched) {
              onAddressSearched();
            }
          } else if (!addressList) {
            setAddressResults([]);
          }
        });
      } else {
        addressSearchApi
          .searchERCOTAddress(zipCode, searchString)
          .then((result) => {
            if (result.length) {
              let _addressList = result.map((address) => {
                return {
                  city: address.City,
                  state: address.State,
                  street_line: address.Address,
                  unit: address.Parsed_UnitNumber,
                  uniqueAddressId: address.ESIID,
                  zipcode: address.Zip,
                  isBusiness: address.Premise_Type !== 'Residential'
                };
              });

              // _addressList.sort(
              //   (addressA, addressB) =>
              //     (zipCode && zipCode === addressA.zipcode ? -1 : 1) ||
              //     ('' + addressA.street_line).localeCompare(
              //       addressB.street_line
              //     ) ||
              //     (addressA.unit &&
              //     !isNaN(addressA.unit) &&
              //     addressB.unit &&
              //     !isNaN(addressB.unit)
              //       ? addressA.unit - addressB.unit
              //       : ('' + addressA.unit).localeCompare(addressB.unit))
              // );

              setAddressResults(_addressList);

              if (onAddressSearched) {
                onAddressSearched();
              }
            } else {
              setAddressResults([]);
            }
          });
      }
    }
  }

  function searchSecondaryAddress(address) {
    let secondaryEntry = {
      ...secondaryEntryDictionary[address.key]
    };

    dataApi
      .listAddressSecondary(address.street_line, address.zipcode)
      .then((addressList) => {
        secondaryEntry.ResultList = addressList.map((_address) => {
          return {
            ...address,
            street_line: _address.Address,
            secondary: _address.Secondary
          };
        });
        secondaryEntry.SortedList = secondaryEntry.ResultList;
        secondaryEntry.Loading = false;
        secondaryEntry.Expanded = true;
        setSecondaryEntryDictionary({
          ...secondaryEntryDictionary,
          [address.key]: secondaryEntry
        });
      });

    // addressSearchApi
    //   .searchAddress(
    //     address.address,
    //     address.street_line +
    //       ' ' +
    //       address.secondary +
    //       ' (' +
    //       address.entries +
    //       ') ' +
    //       address.city +
    //       ' ' +
    //       address.state +
    //       ' ' +
    //       address.zipcode
    //   )
    //   .then(response => {
    //     secondaryEntry.ResultList = response.suggestions;
    //     secondaryEntry.SortedList = response.suggestions;
    //     secondaryEntry.Loading = false;
    //     secondaryEntry.Expanded = true;
    //     setSecondaryEntryDictionary({
    //       ...secondaryEntryDictionary,
    //       [address.key]: secondaryEntry
    //     });
    //   });
  }

  function setAddressResults(addressList) {
    setAddressList(
      addressList.map((address, index) => {
        const key = address.street_line + '_' + index;
        return { ...address, key: key };
      })
    );

    setShowSearchResults(true);

    if (!modal) {
      var element = document.getElementsByClassName('search-results')[0];
      if (element) {
        //element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }

  function setAddress(address, serviceProfileID) {
    if (dataApi.getSessionStorage('sc-selected-resident')) {
      dataApi.setSessionStorage('sc-selected-resident', '');
    }

    dataApi
      .saveServiceAddress(
        address.street_line,
        address.secondary,
        address.city,
        address.state,
        address.zipcode,
        address.uniqueAddressId ? 12 : 1,
        address.uniqueAddressId,
        null,
        null,
        null,
        null,
        null,
        serviceProfileID
      )
      .then((result) => {
        if (result && result.Address) {
          actions.serviceAddressUpdated(result);
        }

        actions.pageLoading(false);

        dataApi.setCookie('sc-zip-code', address.zipcode);
        dataApi.setCookie('sc-internet-offer', '', 12);

        if (afterAddressSelected) {
          afterAddressSelected();
        } else {
          if (siteConfig && siteConfig.id === 'sc') {
            ///stay where you're at
          } else {
            navigate('/dashboard');
          }
        }

        addressSearchTimeout.current = setTimeout(() => {
          if (webSocket.pageLoading) {
            actions.pageLoading(
              true,
              'Building your personalized checklist...'
            );
          }
        }, 2000);
      });

    if (onSelected) {
      onSelected();
    }
  }

  function selectAddress(address) {
    setShowSearchResults(false);

    if (handleAddressSelection) {
      handleAddressSelection(address);
    } else {
      actions.pageLoading(true, 'Saving your address selection...');

      if (!isPreviousAddress) {
        if (siteConfig.id == 'mi') {
          dataApi
            .listServiceProfile(
              address.street_line,
              address.secondary,
              address.city,
              address.state,
              address.zipcode,
              address.uniqueAddressId ? 12 : 1
            )
            .then((serviceProfileList) => {
              if (serviceProfileList.length) {
                if (serviceProfileList.length === 1) {
                  setAddress(address, serviceProfileList[0].ServiceProfileID);
                } else {
                  actions.pageLoading(false);

                  setShowServiceProfileList({
                    address: { ...address },
                    serviceProfileList
                  });
                }
              } else {
                setAddress(address);
              }
            });
        } else {
          setAddress(address);
        }
      } else {
        actions
          .updateVisitorUserInfo(
            {
              ...serviceAddress.Address,
              Previous_Address: address.street_line,
              Previous_Secondary: address.secondary,
              Previous_City: address.city,
              Previous_State: address.state,
              Previous_Zip: address.zipcode
            },
            true
          )
          .then((result) => actions.pageLoading(false));

        if (onSelected) {
          onSelected();
        }
      }
    }
  }

  function submitZipCode() {
    if (zipCode) {
      actions.pageLoading(true, 'Searching plans...');

      if (dataApi.getSessionStorage('sc-selected-resident')) {
        dataApi.setSessionStorage('sc-selected-resident', '');
      }

      dataApi.setCookie('sc-zip-code', zipCode);

      dataApi.setCookie('sc-usage-setting', 'estimated');

      dataApi.saveServiceAddress('', '', '', '', zipCode, 6).then((result) => {
        if (result && result.Address) {
          actions.serviceAddressUpdated(result);
        }

        if (afterAddressSelected) {
          afterAddressSelected();
        } else {
          navigate('/');
        }

        actions.pageLoading(false);
      });

      if (onSelected) {
        onSelected();
      }
    } else {
      document.getElementById('zipCode').focus();
    }
  }

  return (
    <>
      <div
        ref={ref}
        className={
          'address-search' +
          (modal ? ' modal' : '') +
          (isFocused ? ' focused' : '')
        }>
        {addressMode === 'address' ? (
          <div className="form">
            {/* {siteConfig.id === 'sc' ? (
            addressSearchShowZip ? (
              <div className="textfield">
                <TextField
                  value={zipCode}
                  id="zip_search"
                  key="zip-search-input"
                  label={placeholderOnly ? '' : 'Enter your zip code here'}
                  placeholder={placeholderOnly ? addressLabel : null}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    if (event.target.value) {
                      setZipCode(parseInt(event.target.value.substring(0, 5)));
                    } else {
                      setZipCode(null);
                    }
                  }}
                  autoFocus
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      if (zipCode && String(zipCode).length === 5) {
                        submitZipCode();
                      }
                    }
                  }}
                  style={{ marginBottom: '20px', width: '200px' }}
                  onFocus={(event) => event.target.select()}
                  type="number"
                  inputProps={{ inputMode: 'numeric', maxLength: 5 }}
                />
              </div>
            ) : (
              <p className="search-within-zip-notice">
                Searching within {zipCode}{' '}
                <a
                  className="link"
                  onClick={() => setAddressSearchShowZip(true)}>
                  Change Zip
                </a>
              </p>
            )
          ) : null} */}

            <div
              className={
                'textfield' + (hideAddressSearchButton ? ' wide' : '')
              }>
              <TextField
                label={
                  placeholderOnly
                    ? ''
                    : addressLabel
                    ? addressLabel
                    : modal
                    ? 'Enter your address here '
                    : 'Enter your service address to browse plans'
                }
                name="searchTerm"
                placeholder={placeholderOnly ? addressLabel : null}
                fullWidth
                variant="outlined"
                value={addressSearchString}
                autoFocus={(modal || autoFocus) && autoFocus !== false}
                onFocus={() => {
                  setIsFocused(true);
                  if (addressSearchString) {
                    searchAddress(addressSearchString);
                  }
                }}
                onBlur={() => setIsFocused(false)}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                //   inputRef={input => input && input.focus()}
                onChange={(event) => {
                  var searchString = event.target.value;

                  setAddressSearchString(searchString);
                  setShowSearchResults(searchString);

                  if (addressSearchTimeout.current) {
                    clearTimeout(addressSearchTimeout.current);
                  }

                  addressSearchTimeout.current = setTimeout(
                    () => searchAddress(searchString),
                    300
                  );
                }}
              />
            </div>

            {!hideAddressSearchButton && (
              <div className="button">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!addressSearchString}
                  fullWidth="true">
                  {searchButtonLabel ? searchButtonLabel : 'Search'}
                </Button>
              </div>
            )}

            {addressList && showSearchResults ? (
              <div className="search-results">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            style={{ float: 'right' }}
                            aria-label="close"
                            onClick={() => setShowSearchResults(false)}>
                            <CloseIcon />
                          </IconButton>
                          <strong>Select your address in the list below</strong>
                          {siteConfig.id === 'mi' ? (
                            <Tooltip
                              enterTouchDelay={0}
                              title="Our service depends on an accurate USPS address.  We searched the latest data from the USPS database to build the list below. Please double check that you have typed your address correctly.">
                              <a className="link">Can't find it?</a>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addressList.length ? (
                        addressList.map((address, addressIndex) => (
                          <>
                            <TableRow key={address.key}>
                              <TableCell component="td" scope="row">
                                <>
                                  <Button
                                    variant="text"
                                    className="select-address"
                                    onClick={() => {
                                      if (
                                        address.secondary &&
                                        !suppressSecondarySelect
                                      ) {
                                        let secondaryEntry = {
                                          Loading: false,
                                          Expanded: false
                                        };

                                        if (
                                          secondaryEntryDictionary[address.key]
                                        ) {
                                          secondaryEntry = {
                                            ...secondaryEntryDictionary[
                                              address.key
                                            ]
                                          };
                                        }

                                        secondaryEntry.Expanded =
                                          !secondaryEntry.Expanded;

                                        if (!secondaryEntry.ResultList) {
                                          secondaryEntry.Loading = true;
                                          searchSecondaryAddress(address);
                                        }

                                        setSecondaryEntryDictionary({
                                          ...secondaryEntryDictionary,
                                          [address.key]: secondaryEntry
                                        });
                                      } else {
                                        selectAddress(address);
                                      }
                                    }}
                                    endIcon={
                                      address.secondary &&
                                      !suppressSecondarySelect ? (
                                        <>
                                          <span className="unit">
                                            Select {address.secondary}
                                          </span>
                                          {secondaryEntryDictionary[
                                            address.key
                                          ] &&
                                          secondaryEntryDictionary[address.key]
                                            .Expanded ? (
                                            <ExpandLessIcon></ExpandLessIcon>
                                          ) : (
                                            <ExpandMoreIcon></ExpandMoreIcon>
                                          )}
                                        </>
                                      ) : (
                                        <ChevronRightIcon></ChevronRightIcon>
                                      )
                                    }>
                                    <FormattedAddress
                                      address={address}></FormattedAddress>
                                  </Button>
                                </>
                              </TableCell>
                            </TableRow>
                            {secondaryEntryDictionary[address.key] &&
                            secondaryEntryDictionary[address.key].Expanded ? (
                              <>
                                {secondaryEntryDictionary[address.key]
                                  .Loading ? (
                                  <LinearProgress />
                                ) : secondaryEntryDictionary[address.key]
                                    .ResultList ? (
                                  <>
                                    <TableRow
                                      key={
                                        address.street_line +
                                        '_search' +
                                        '_' +
                                        addressIndex
                                      }
                                      className="secondary-search">
                                      <TableCell component="td" scope="row">
                                        <>
                                          <TextField
                                            id={
                                              'secondary_search_input_' +
                                              addressIndex
                                            }
                                            placeholder={
                                              'Find your ' +
                                              address.secondary.toLowerCase()
                                            }
                                            variant="standard"
                                            value={
                                              secondaryEntryDictionary[
                                                address.key
                                              ].SearchString
                                            }
                                            onChange={(event) => {
                                              const value = event.target.value;

                                              setSecondaryEntryDictionary({
                                                ...secondaryEntryDictionary,
                                                SearchString: value
                                              });

                                              const searchString = event.target
                                                .value
                                                ? String(value).toLowerCase()
                                                : '';

                                              if (
                                                secondarySearchTimeout.current
                                              ) {
                                                clearTimeout(
                                                  secondarySearchTimeout.current
                                                );
                                              }

                                              secondarySearchTimeout.current =
                                                setTimeout(() => {
                                                  let sortedList;

                                                  if (searchString) {
                                                    let closestMatchList =
                                                      secondaryEntryDictionary[
                                                        address.key
                                                      ].ResultList.filter(
                                                        (_address) =>
                                                          _address.secondary &&
                                                          _address.secondary.indexOf(
                                                            searchString
                                                          ) === 0
                                                      );

                                                    let partialMatchList =
                                                      secondaryEntryDictionary[
                                                        address.key
                                                      ].ResultList.filter(
                                                        (_address) =>
                                                          _address.secondary &&
                                                          _address.secondary
                                                            .toLowerCase()
                                                            .indexOf(
                                                              searchString
                                                            ) > 0
                                                      );

                                                    let noMatchList =
                                                      secondaryEntryDictionary[
                                                        address.key
                                                      ].ResultList.filter(
                                                        (_address) =>
                                                          _address.secondary &&
                                                          _address.secondary
                                                            .toLowerCase()
                                                            .indexOf(
                                                              searchString
                                                            ) === -1
                                                      );

                                                    sortedList =
                                                      closestMatchList.concat(
                                                        partialMatchList
                                                      );
                                                    sortedList =
                                                      sortedList.concat(
                                                        noMatchList
                                                      );
                                                  } else {
                                                    sortedList =
                                                      secondaryEntryDictionary[
                                                        address.key
                                                      ].ResultList;
                                                  }

                                                  setSecondaryEntryDictionary({
                                                    ...secondaryEntryDictionary,
                                                    [address.key]: {
                                                      ...secondaryEntryDictionary[
                                                        address.key
                                                      ],
                                                      SortedList: sortedList
                                                    }
                                                  });
                                                }, 200);
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <SearchIcon />
                                                </InputAdornment>
                                              )
                                            }}
                                          />
                                        </>
                                      </TableCell>
                                    </TableRow>
                                    {secondaryEntryDictionary[
                                      address.key
                                    ].SortedList.map(
                                      (
                                        secondaryAddress,
                                        secondaryAddressIndex
                                      ) => {
                                        return (
                                          <TableRow
                                            key={
                                              'address_' +
                                              secondaryAddress.street_line +
                                              '_' +
                                              secondaryAddressIndex
                                            }>
                                            <TableCell
                                              component="td"
                                              scope="row">
                                              <>
                                                <Button
                                                  variant="text"
                                                  className="select-address secondary"
                                                  onClick={() =>
                                                    selectAddress(
                                                      secondaryAddress
                                                    )
                                                  }
                                                  endIcon={
                                                    <ChevronRightIcon></ChevronRightIcon>
                                                  }>
                                                  <FormattedAddress
                                                    address={secondaryAddress}
                                                    secondary={
                                                      true
                                                    }></FormattedAddress>
                                                </Button>
                                              </>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ))
                      ) : (
                        <TableRow className="no-results">
                          <TableCell component="td" scope="row">
                            We couldn't find a match for that address.
                            <br />
                            Please double check that you typed it correctly.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={'form' + (inFocus ? ' focus' : '')}>
            <div className="textfield">
              <TextField
                value={zipCode}
                id="zip_search"
                key="zip-search-input"
                label={
                  placeholderOnly
                    ? ''
                    : addressLabel
                    ? addressLabel
                    : 'Enter your zip code to browse plans'
                }
                placeholder={placeholderOnly ? addressLabel : null}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  if (event.target.value) {
                    setZipCode(parseInt(event.target.value.substring(0, 5)));
                  } else {
                    setZipCode(null);
                  }
                }}
                autoFocus={autoFocus !== undefined ? autoFocus : true}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    if (zipCode && String(zipCode).length === 5) {
                      submitZipCode();
                    }
                  }
                }}
                onFocus={(event) => {
                  setInFocus(true);
                  event.target.select();
                }}
                onBlur={() => setInFocus(false)}
                type="number"
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 5,
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off'
                  }
                }}
              />
            </div>
            <div className="button">
              <Button
                variant="contained"
                color="primary"
                disabled={!zipCode || String(zipCode).length !== 5}
                fullWidth="true"
                onClick={() => submitZipCode()}>
                {searchButtonLabel ? searchButtonLabel : 'Search'}
              </Button>
            </div>
          </div>
        )}

        {!hideSearchBy && (!addressList || !showSearchResults) ? (
          <div className="tabs">
            <div className="tab-label">Search by:</div>

            <FormControl component="fieldset" className="search-by">
              <RadioGroup
                aria-label="addressMode"
                name="addressMode"
                value={addressMode}
                onChange={(event, newValue) => {
                  setAddressMode(newValue);
                }}>
                <FormControlLabel
                  value="address"
                  control={<Radio color="primary" />}
                  label={<>Service Address</>}
                />

                <FormControlLabel
                  value="zip"
                  control={<Radio color="primary" />}
                  label={<>Zip Code</>}
                />
              </RadioGroup>
            </FormControl>

            {/* <Tabs
              value={addressMode}
              onChange={(event, newValue) => {
                setAddressMode(newValue);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="full width tabs example">
              <Tab label="Service Address" value="address" />
              <Tab label="Zip Code" value="zip" />
            </Tabs> */}
          </div>
        ) : null}

        {modal &&
        ((serviceAddress &&
          serviceAddress.SearchHistory &&
          serviceAddress.SearchHistory.length) ||
          (authUser && authUser.ServiceAddressHistory)) &&
        !hideHistory ? (
          <div className="search-results">
            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Location History</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(authUser && authUser.ServiceAddressHistory
                    ? authUser.ServiceAddressHistory
                    : serviceAddress.SearchHistory
                  ).map((address, addressIndex) => (
                    <TableRow
                      key={'address_' + address.Address + '_' + addressIndex}
                      className={!address.Address ? 'single-line' : ''}>
                      <TableCell component="td" scope="row">
                        <>
                          <Button
                            variant="text"
                            className="select-address"
                            onClick={() =>
                              selectAddress({
                                street_line: address.Address,
                                secondary: address.Secondary,
                                city: address.City,
                                state: address.State,
                                zipcode: address.Zip
                              })
                            }
                            endIcon={<ChevronRightIcon></ChevronRightIcon>}>
                            <FormattedAddress
                              address={{
                                street_line: address.Address,
                                secondary: address.Secondary,
                                city: address.City,
                                state: address.State,
                                zipcode: address.Zip
                              }}
                              secondary={true}></FormattedAddress>
                          </Button>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}

        <div className="mobile-sizer"></div>
      </div>

      {showServiceProfileList ? (
        <GeneralPopup
          title={'Service Profile Selection'}
          className={'service-profile-select-popup'}
          message={
            <>
              <div className="service-profile-select">
                <p>
                  <strong>
                    There are multiple service profiles saved for this address.
                    Which would you like to use?
                  </strong>
                </p>

                <div className="results">
                  <TableContainer>
                    <Table aria-label="simple table" stickyHeader={true}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Service Profile</TableCell>
                          <TableCell>Partner</TableCell>
                          <TableCell>Role</TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showServiceProfileList.serviceProfileList.map(
                          (_serviceProfile) => (
                            <>
                              <TableRow key={_serviceProfile.ServiceProfileID}>
                                <TableCell>
                                  {_serviceProfile.ServiceProfileName ? (
                                    _serviceProfile.ServiceProfileName
                                  ) : (
                                    <em>In Progress</em>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {_serviceProfile.PartnerName}
                                </TableCell>
                                <TableCell>{_serviceProfile.Role}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      actions.pageLoading(
                                        true,
                                        'Saving your address selection...'
                                      );

                                      console.log(
                                        '_serviceProfile.ServiceProfileID',
                                        _serviceProfile.ServiceProfileID
                                      );
                                      setAddress(
                                        showServiceProfileList.address,
                                        _serviceProfile.ServiceProfileID
                                      );
                                    }}>
                                    Select
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </>
          }
          closeFunc={() => setShowServiceProfileList(null)}
          closeLabel={'Close'}
        />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      updateVisitorUserInfo: bindActionCreators(
        serviceAddressActions.updateVisitorUserInfo,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearch);
